import React from 'react';
import { shape, string } from 'prop-types';
import { cartRedirect } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Button, Container } from './BuyButtonServices.styles';
import { getBaseOrderEligibleUrl } from './utils/getOrderEligibleUrl';
var BuyButtonServices = function BuyButtonServices(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var buttonText = staticProps.buttonText;
  var route = structure.route,
    config = structure.config;
  var _ref2 = config || {},
    cartUrl = _ref2.cartUrl,
    domain = _ref2.domain;
  var orderEligibleUrl = getBaseOrderEligibleUrl(route.productId);
  var handleRedirectOnClick = function handleRedirectOnClick() {
    return cartRedirect({
      cartUrl: cartUrl,
      next: config ? "".concat(domain).concat(orderEligibleUrl) : '',
      type: 'login'
    });
  };
  return React.createElement(Container, null, React.createElement(Button, {
    color: "secondary",
    "data-testid": "BuyButtonServices-redirect-button",
    full: true,
    onClick: handleRedirectOnClick,
    size: "medium",
    type: "button"
  }, buttonText));
};
process.env.NODE_ENV !== "production" ? BuyButtonServices.propTypes = {
  "static": shape({
    buttonText: string.isRequired
  }).isRequired,
  structure: shape({
    route: shape({
      productId: string.isRequired
    })
  }).isRequired
} : void 0;
BuyButtonServices.ssr = false;
BuyButtonServices.displayName = 'BuyButtonServices';
export default withLayoutProps(BuyButtonServices);